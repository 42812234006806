<template>
  <v-container class="brain-storming mb-10">
    <div class="d-flex justify-space-between mt-20 mb-15">
      <p class="mb-0 text-h3">Brainstorming</p>
      <div class="text-end align-self-end">
        <p class="mb-0">發想與定義需求</p>
      </div>
    </div>

    <v-row>
      <v-col cols="12" md="6">
        <div class="px-10 py-5">
          <DotText text="情境" :textColor="title1Color" :dotColor="dot1Color" />
          <p v-html="content1" class="primary--text"></p>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <div class="px-10 py-5">
          <DotText
            text="困難點"
            :textColor="title2Color"
            :dotColor="dot2Color"
          />
          <p v-html="content2" class="primary--text"></p>
        </div>
      </v-col>
    </v-row>

    <v-row v-if="src1 || src2">
      <v-col cols="6">
        <v-img :src="src1" contain></v-img>
      </v-col>
      <v-col cols="6">
        <v-img :src="src2" contain></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    src1: {
      type: String,
    },
    src2: {
      type: String,
    },
    dot1Color: {
      type: String,
    },
    dot2Color: {
      type: String,
    },
    title1Color: {
      type: String,
    },
    title2Color: {
      type: String,
    },
    content1: {
      type: String,
    },
    content2: {
      type: String,
    },
  },
};
</script>